function getDeviceSize() {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1200) {
        return 'extra-large';
    } else if (screenWidth >= 992) {
        return 'large';
    } else if (screenWidth >= 768) {
        return 'medium';
    } else {
        return 'small';
    }
}

function getLogoByText(text) {
    switch (text) {
        case "Free WiFi":
            return "wifi"
        case "View of snowcapped Himalayas":
            return "himalaya"
        case "Airy & spacious rooms":
            return "air"
        case "Comfortable beds and mattresses":
            return "bed"
        case "Selling Fan (No AC required)":
            return "fan"
        case "Dining Table and Chairs":
            return "dining"
        case "Smart /LED TV with satellite tuner":
            return "television"
        case "Cupboard":
            return "cupboard"
        case "Tea/Coffee Maker (Electric Kettle)":
            return "teamaker"
        case "Geysers (Hot and Cold Running Water)":
            return "hotwater"
        case "Attached private bathroom":
            return "bathroom"
        case "Premium Toiletries":
            return "toiletries"
        case "Kitchenette/Separate seating area":
            return "kitchen"
        case "Microwave":
            return "microwave"
        case "Gas Stove":
            return "gasstove"

        default:
            return "balcony"
    }
}


export { getDeviceSize, getLogoByText }