import React from 'react'

export default function ProductCard({ clickHandler, imageLocation, heading, description }) {
    return (
        <div className='w-full flex flex-col font-Lora ' onClick={clickHandler} >
            <img loading="lazy" alt={heading + ' image'} src={imageLocation} className='w-full object-cover hover:opacity-50 cursor-pointer h-[300px]' />
            <div className='w-full flex flex-col items-center gap-5 py-5 bg-gray-200 '>
                <p className='w-[90%] h-[50px] max-md:h-fit text-xl max-md:text-lg text-secondary font-semibold cursor-pointer'>{heading}</p>
                <p className='w-[90%] text-sm max-md:text-xs max-h-[400px] line-clamp-6 overflow-hidden'>{description}</p>
            </div>
        </div>
    )
}
