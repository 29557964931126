import React, { useState } from 'react'
import { HOTEL_NAME, HOTEL_ROOM_TYPES_AND_INFO, META_CONTENT, PAGE_TITLES } from '../constants'
import LeftAndRightProductDescriptionSection from '../components/LeftAndRightProductDescriptionSection'
import { Helmet } from 'react-helmet'

export default function RoomsPage() {

    const clickHandler = (type) => {
        window.open(`Rooms/${type}`, "_self")
    }


    return (
        <div className='py-20 flex flex-col justify-center items-center gap-10 font-Lora '>
            <Helmet>
                <title>{PAGE_TITLES["rooms"]}</title>
                <meta name='description' content={META_CONTENT["rooms"].description} />
                <meta name="robots" content="index, follow" ></meta>
            </Helmet>
            <h1 className='text-3xl max-md:text-xl text-secondary'>Rooms at the {HOTEL_NAME}</h1>
            <div className='flex flex-col max-md:gap-10 w-full relative'>
                {Object.keys(HOTEL_ROOM_TYPES_AND_INFO).map((type, ind) => {
                    return (
                        <LeftAndRightProductDescriptionSection key={type} type={type} imageOnLeft={(ind % 2) == 0} description={HOTEL_ROOM_TYPES_AND_INFO[type].description} heading={HOTEL_ROOM_TYPES_AND_INFO[type].longName}>
                            <img className='w-full object-cover h-full' src={"images/hotel-images/" + HOTEL_ROOM_TYPES_AND_INFO[type].photos[0] + ".jpg"} />
                        </LeftAndRightProductDescriptionSection>
                    )
                })}
            </div>
        </div >
    )
}

