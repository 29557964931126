import React from 'react'
import TerrifTable from '../components/TerrifTable'
import { IoMdContact } from 'react-icons/io'
import CustomButton from '../components/CustomButton'
import { Helmet } from 'react-helmet'
import { HOTEL_AMENITIES, META_CONTENT, PAGE_TITLES } from '../constants'
import HotelAmenities from '../components/HotelAmenities'

export default function TerrifPage() {
    const onButtonClick = () => {
        window.open("/Contact", "_self")
    }
    return (
        <div className='pt-10 flex flex-col gap-5 justify-center items-center bg-tertiary'>
            <Helmet>
                <title>{PAGE_TITLES["tarrif"]}</title>
                <meta name='tarrif' content={META_CONTENT["home"].description} />
                <meta name="robots" content="index, follow" ></meta>
            </Helmet>
            <div className='w-[60%] max-md:w-[90%]'>
                <TerrifTable />
            </div>
            <div className='w-[90%]'>
                <CustomButton text={"Contact Us"} icon={<IoMdContact size={35} />} onClickHandler={onButtonClick} />
            </div>
            <div className='w-[100%] flex flex-col gap-10 items-center py-20 max-md:py-10 z-10 bg-gray-200'>
                <HotelAmenities amenities={HOTEL_AMENITIES} heading={"The Advantages"} />
            </div>
        </div>
    )
}
