import React from 'react'

export default function CustomButton({ text, icon, onClickHandler }) {
    return (
        <div className='w-[100%] justify-end flex mt-5' onClick={onClickHandler}>
            <div className='flex flex-row gap-2 bg-secondary text-text_primary p-2 max-sm:p-1 rounded-sm font-Lora'>
                {icon}
                <button className='text-xl max-md:text-lg max-sm:text-sm'>{text}</button>
            </div>
        </div>
    )
}
