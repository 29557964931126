import React from 'react';
import CustomCarousel from '../components/Carousel';
import HotelAmenities from '../components/HotelAmenities';
import HotelAddressAndContactSection from '../components/HotelAddressAndContactSection';
import GalleryPreview from '../components/GalleryPreview';
import FloatingButton from '../components/FloatingButton';
import { HOMEPAGE_CAROUSEL_IMAGES, HOTEL_AMENITIES, HOTEL_PHONE1, HOTEL_PHONE2, HOTEL_ROOM_TYPES_AND_INFO, META_CONTENT, PAGE_TITLES } from '../constants';
import ProductCards from '../components/ProductCards';
import { Helmet } from "react-helmet";
import ShortDescription from '../components/ShortDescription';

export default function HomePage() {
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleOnChange = index => {
        setSelectedIndex(index);
    };


    return (
        <div className='flex flex-col justify-center items-center'>
            <Helmet>
                <title>{PAGE_TITLES["home"]}</title>
                <meta name='description' content={META_CONTENT["home"].description} />
                <meta name="robots" content="index, follow" ></meta>
            </Helmet>
            <img
                alt='hotel-image'
                src='/images/hotelbg.webp'
                className='fixed top-0 left-0 w-full h-full z-[-10] object-cover'
            />
            <div className='w-full mt-[-5rem] max-md:hidden'>
                <CustomCarousel onChangeHandler={handleOnChange}>
                    {HOMEPAGE_CAROUSEL_IMAGES.map((image, ind) => (
                        <img
                            key={image}
                            alt={image}
                            src={`images/hotel-images/${image}.jpg`}
                            className={`${selectedIndex === ind ? 'zoom-in-out' : ''} w-full h-[85vh] object-cover`}
                        />
                    ))}
                </CustomCarousel>
            </div>
            <div className='w-full hidden mt-[-5rem] max-md:flex'>
                {/* Lazy load and optimize the outdoor image */}
                <img
                    placeholder={<img src='images/placeholder_img.jpg' />}
                    src={`images/hotel-images/mobile_homepage.jpg`}
                    className={`w-full h-[50vh] object-cover`}
                />
            </div>
            <HotelAddressAndContactSection />
            <ShortDescription />
            <div className='w-full bg-[rgba(0,0,0,0.6)] py-10 flex justify-center'>
                <ProductCards products={Object.keys(HOTEL_ROOM_TYPES_AND_INFO)} />
            </div>
            <div className="flex flex-col w-full gap-[320px] max-md:gap-[200px]">
                <div className='w-[100%] flex flex-col gap-10 items-center py-20 max-md:py-10 z-10 bg-gray-200'>
                    <HotelAmenities amenities={HOTEL_AMENITIES} heading={"The Advantages"} />
                </div>
                <div className='w-[100%] z-10 flex justify-center bg-gray-200'>
                    <GalleryPreview />
                </div>
            </div>
            {/* Lazy load and optimize the WhatsApp button image */}
            <a href={`https://wa.me/+919315373630`} rel="noopener" target='_blank'>
                <FloatingButton >
                    <img alt='whatsapp-logo' effect='blur' src='images/whatsapp.webp' className='w-[65px] object-cover max-md:w-[50px]' />
                </FloatingButton>
            </a>

        </div >
    );
}
