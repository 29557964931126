import React from 'react'
import { getActiveImages } from '../helpers/galleryHelper'
import CustomButton from './CustomButton'
import { IoMdImages } from 'react-icons/io'
import { HOMEPAGE_GALLERY_PREVIEW } from '../constants';

export default function GalleryPreview() {

    const onButtonClick = () => {
        window.open("/gallery", "_self")
    }
    return (
        <div className='w-[90%] flex flex-col gap-10  py-10 self-center font-Lora'>
            <p className='text-3xl max-md:text-xl'>Gallery</p>
            <div className='flex flex-row flex-wrap justify-center gap-5'>
                {HOMEPAGE_GALLERY_PREVIEW.map((image) => {
                    return (
                        <img
                            key={image}
                            alt='rooms-image'
                            src={`images/hotel-images/gallery_preview/${image}.webp`}
                            className=' w-[350px] max-md:w-[200px] max-sm:w-[150px]' />
                    )
                })}
            </div>
            <div className='w-full flex justify-end'>
                <CustomButton text={"Browse Gallery"} icon={<IoMdImages size={35} />} onClickHandler={onButtonClick} />
            </div>
        </div>
    )
}
