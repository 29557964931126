import React from 'react'
import { HOTEL_ADDRESS, HOTEL_MAIL1, HOTEL_NAME, HOTEL_PHONE1, HOTEL_PHONE2, HOTEL_PHONE3, HOTEL_PHONE4, NAVBARPAGES } from '../constants'
import { IoIosMail } from 'react-icons/io'
import { FaPhoneAlt } from 'react-icons/fa'
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa6'
import DesignBySection from './DesignBySection'
export default function Footer() {
    return (
        <footer className='relative font-Lora flex flex-col gap-10 pt-10 justify-center items-center w-full font-normal bg-transparent text-text_primary'>
            <img src='/images/footer.webp' alt='footer-img' className='object-cover z-[-10] absolute top-0 left-0 w-full h-full' />
            <div className='flex flex-col gap-2 justify-center items-center max-w-[80%] '>
                <p className='max-md:text-md'>{HOTEL_NAME}</p>
                <p className='max-md:text-md'>{HOTEL_ADDRESS}</p>
            </div>
            <div className='flex flex-row justify-center flex-wrap gap-4 text-sm px-2'>
                <div className='flex flex-row gap-2 items-center'>
                    <IoIosMail className=' text-lg' />
                    <p className='max-md:text-sm'>{HOTEL_MAIL1}</p>
                </div>
                <div className='flex flex-row gap-2 items-center'>
                    <FaPhoneAlt className=' text-lg' />
                    <p className='max-md:text-sm'>{HOTEL_PHONE1} | {HOTEL_PHONE2} | {HOTEL_PHONE3} | {HOTEL_PHONE4}</p>
                </div>
            </div>
            <div className='flex flex-col items-center gap-4'>
                <p>Get in touch</p>
                <div className='flex flex-row gap-4'>
                    <FaFacebook className="text-3xl max-md:text-2xl cursor-pointer" />
                    <FaWhatsapp className="text-3xl max-md:text-2xl cursor-pointer" />
                    <FaInstagram className="text-3xl max-md:text-2xl cursor-pointer" />
                </div>

            </div>
            <div className='flex flex-col gap-4 w-full items-center'>
                <hr className='w-[80%]' />
                <div className='flex flex-row gap-4'>
                    {NAVBARPAGES.map((page) => {
                        return (
                            <a aria-label={"Know more about" + page} key={page} href={page === "Home" ? "/" : "/" + page.charAt(0).toLowerCase() + page.slice(1)} className='max-md:text-sm'>{page}</a>
                        )
                    })}
                </div>
                <div className='w-full'>
                    <DesignBySection />
                </div>
            </div>

        </footer>
    )
}
