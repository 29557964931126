import React, { useState } from 'react'
import { META_CONTENT, PAGE_TITLES, ROOM_CATEGORIES } from '../constants'
import { getActiveImages } from '../helpers/galleryHelper'
import CustomModal from '../components/CustomModal'
import ClickableCategoriesSection from '../components/ClickableCategoriesSection'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Helmet } from 'react-helmet'


export default function GalleryPage() {
    const [activeImageCategory, setActiveImageCategory] = useState("All Photos")
    const [imageForModal, setImageForModal] = useState(null);

    const closeModal = () => {
        setImageForModal(null)
    }
    const clickHandler = (category) => {
        setActiveImageCategory(category)
    }

    return (
        <div className='w-full flex flex-col pb-20 font-Lora justify-center items-center'>
            <Helmet>
                <title>{PAGE_TITLES["gallery"]}</title>
                <meta name='description' content={META_CONTENT["gallery"].description} />
                {/* <meta name="robots" content="index, follow" /> */}
            </Helmet>
            {imageForModal && <CustomModal closeModal={closeModal}>
                <LazyLoadImage
                    effect='blur'
                    alt={imageForModal} src={`images/hotel-images/${imageForModal}.jpg`} className='w-[50vw] max-md:w-[80vw] max-md:h-[50vh] h-[70vh] object-cover max-md:object-contain' />
            </CustomModal>}
            <ClickableCategoriesSection activeCategory={activeImageCategory} categories={ROOM_CATEGORIES} clickHandler={clickHandler} />
            <div className='w-[90%] max-md:w-[100%] flex flex-col gap-10 items-center bg-gray-200 py-10'>
                <h1 className='text-3xl max-md:text-xl'>{activeImageCategory}</h1>
                <div className='flex flex-row flex-wrap justify-center gap-5'>
                    {getActiveImages(activeImageCategory).map((image) => {
                        return (
                            <img
                                key={image}
                                alt={image}
                                src={`images/hotel-images/gallery_preview/${image}.webp`}
                                className='cursor-pointer w-[280px]  object-cover max-md:w-[200px] max-sm:w-[150px]'
                                onClick={() => { setImageForModal(image) }}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
