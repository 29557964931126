import React from 'react'
import { HOMEPAGE_DESCRIPTION, HOMEPAGE_TITLE } from '../constants'

export default function ShortDescription() {
    return (
        <div className=' font-Lora bg-gray-200 w-full py-10 flex flex-col justify-center items-center gap-8'>
            <h1 className='text-3xl max-md:text-xl w-[60%] text-secondary max-md:w-[90%]'>{HOMEPAGE_TITLE}</h1>
            <h2 className=' whitespace-pre-line text-justify leading-7 w-[60%] max-md:w-[90%] max-md:text-sm'>{HOMEPAGE_DESCRIPTION}</h2>
        </div>
    )
}
