import React from 'react'
import { useParams } from 'react-router-dom';
import CustomCarousel from '../components/Carousel';
import { HOTEL_ROOM_TYPES_AND_INFO } from '../constants';
import HotelAmenities from '../components/HotelAmenities';
import ClickableCategoriesSection from '../components/ClickableCategoriesSection';
import RateCard from '../components/RateCard';

export default function IndividualRoomPage() {
    const { roomType } = useParams()
    const clickHandler = (type) => {
        window.open(`${type}`, "_self")
    }
    return (
        <div className='w-full flex flex-col items-center font-Lora'>
            <ClickableCategoriesSection categories={Object.keys(HOTEL_ROOM_TYPES_AND_INFO)} activeCategory={roomType} clickHandler={clickHandler} />
            <img loading="lazy" alt='hotel-benifits' src='/images/hotel-images/room7.jpg' className='fixed w-full h-full object-cover top-[5rem] left-0 z-[-10]' />
            <div className='w-full flex flex-col gap-10 items-center py-10  max-md:w-[100%] bg-white'>
                <h2 className='text-3xl max-md:text-xl text-secondary'>{HOTEL_ROOM_TYPES_AND_INFO[roomType].longName}</h2>
                <div className='w-[70%] max-md:h-[50vh] max-md:w-[90%]'>
                    <CustomCarousel>
                        {
                            HOTEL_ROOM_TYPES_AND_INFO[roomType].photos.map((photo) => {
                                return (
                                    <img key={photo} alt={photo} src={`/images/hotel-images/${photo}.jpg`} className='w-full h-[80vh] max-md:h-[50vh] object-cover ' />
                                )
                            }
                            )
                        }
                    </CustomCarousel>
                </div>
                <p className='w-[70%] max-md:w-[90%] max-md:text-xs text-left leading-6 whitespace-pre-line'>{HOTEL_ROOM_TYPES_AND_INFO[roomType].description}</p>
                <div className='w-[70%] max-md:w-[90%] flex flex-row justify-between'>
                    {
                        Object.keys(HOTEL_ROOM_TYPES_AND_INFO[roomType].rates).map((rate, ind) => {
                            return <div className='w-[40%]'>
                                <a href='/tarrif'>
                                    <RateCard textColor={ind == 0 ? "text-secondary" : "text-white"} bgColor={ind == 0 ? "bg-white" : "bg-secondary"} heading={rate} rate={HOTEL_ROOM_TYPES_AND_INFO[roomType].rates[rate]} />
                                </a>
                            </div>
                        })
                    }

                </div>
            </div>
            <div className='w-full flex flex-col gap-10 items-center py-20 max-md:py-10 z-10 mt-[300px] max-md:mt-[200px] bg-gray-200'>
                <HotelAmenities amenities={HOTEL_ROOM_TYPES_AND_INFO[roomType].amenities} heading={"Room Amenities"} />
                {/* <img loading="lazy"   src='/images/hotel-amenities.webp' className='object-contain self-center rounded-xl w-[40%] max-md:w-[90%]'></img> */}
            </div>
        </div>
    )
}