import React, { useEffect, useState } from 'react'
import { NAVBARPAGES } from '../constants'
import { getDeviceSize } from '../utils/utils'
import { CiMenuBurger } from "react-icons/ci";

export default function Navbar() {
    const [currentDevice, setCurrentDevice] = useState(getDeviceSize())
    const [openMenu, setOpenMenu] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            setCurrentDevice(getDeviceSize());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='sticky top-0 z-50'>
            <div className='flex flex-row justify-between gap-16 items-center px-10 h-20 bg-[rgba(255,255,255,.7)] shadow-lg'>
                <a href='/' aria-label='homepage'>
                    <div className='rounded-[50%] bg-white p-1'>
                        <img loading="lazy" alt='hotel-logo' src='/logo.png' className='h-16 w-16 object-contain' />
                    </div>
                </a>                {(currentDevice === "large" || currentDevice === "extra-large") ? <div className='flex flex-row gap-7'>
                    {
                        NAVBARPAGES.map((page) => {
                            return (
                                <a aria-label={"Know more about " + page} key={page} href={page === "Home" ? "/" : "/" + page.charAt(0).toLowerCase() + page.slice(1)} className='text-secondary text-lg font-Lora font-bold cursor-pointer'
                                > {page}</a>
                            )
                        })
                    }
                </div> :
                    <CiMenuBurger
                        size={25}
                        onClick={() => { setOpenMenu(!openMenu) }}
                    />
                }

            </div>
            {
                (currentDevice !== "large" && currentDevice !== "extra-large") && openMenu &&
                <div className='fixed right-0 flex flex-col gap-4 py-2  w-[50vw] h-[100vh] shadow-lg bg-[rgba(255,255,255,.7)]'>
                    {
                        NAVBARPAGES.map((page) => {
                            return (

                                <a aria-label={"Know more about " + page} key={page} href={page === "Home" ? "/" : "/" + page.charAt(0).toLowerCase() + page.slice(1)} className='text-secondary text-lg font-Lora font-bold cursor-pointer'
                                > {page}</a>

                            )
                        })
                    }
                </div>
            }
        </div >
    )
}
