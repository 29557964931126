import React, { useRef, useState } from 'react';
import { HOTEL_ADDRESS, HOTEL_MAIL1, HOTEL_NAME, HOTEL_PHONE1, HOTEL_PHONE2, HOTEL_PHONE3, HOTEL_PHONE4, META_CONTENT, PAGE_TITLES } from '../constants';
import { Helmet } from 'react-helmet';
import { FaLocationDot } from "react-icons/fa6";
import { IoMailSharp } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import FloatingButton from '../components/FloatingButton';
import CustomModal from "../components/CustomModal"

const ContactPage = () => {
    const nameRef = useRef();
    const emailRef = useRef();
    const messageRef = useRef();
    const [isSendingEmail, setIsSendingEmail] = useState(false)
    const handleSubmit = async (e) => {
        setIsSendingEmail(true)
        e.preventDefault();
        let payLoad = {
            "name": nameRef.current.value.trim(),
            "email": emailRef.current.value.trim(),
            "message": messageRef.current.value.trim()
        }
        let response = await fetch(
            "https://mailingserver.onrender.com/send-email", {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            mode: "cors",
            body: JSON.stringify(payLoad)
        }
        )
        if (response.status == 200) {
            let data = await response.json();
            if (data?.success) {
                alert(data?.message)
            }
        }
        else {
            alert('There is some problem sending the qerry please contact by number instead!')
        }
        setIsSendingEmail(false)
        nameRef.current.value = ""
        emailRef.current.value = ""
        messageRef.current.value = ""
    };
    return (
        <div className="flex flex-col gap-10 py-10 justify-center items-center font-Lora max-md:flex-col w-full bg-pagebg">
            <Helmet>
                <title>{PAGE_TITLES["contact"]}</title>
                <meta name='description' content={META_CONTENT["contact"].description} />
            </Helmet>
            {isSendingEmail && <CustomModal>
                <p>This may take a minute..</p>
            </CustomModal>}
            <a href={`https://wa.me/+919315373630`} rel="noopener" target='_blank'>
                <FloatingButton >
                    <img alt='whatsapp-logo' effect='blur' src='images/whatsapp.webp' className='w-[65px] object-cover max-md:w-[50px]' />
                </FloatingButton>
            </a>
            <h2 className='text-secondary text-3xl'>Contact Us</h2>
            <div className='flex flex-row max-md:flex-col justify-center items-center gap-10 w-full'>
                <div className='flex flex-col gap-10 w-[40%] max-md:w-[90%]'>
                    <div className='flex flex-col items-start gap-4 w-full'>
                        <h1 className='text-secondary text-2xl max-sm:text-lg'>{HOTEL_NAME}</h1>
                        <div className='flex flex-row gap-5 items-center'>
                            <FaLocationDot />
                            <p className='text-left max-sm:text-sm'>{HOTEL_ADDRESS}</p>
                        </div>
                        <div className='flex flex-row gap-5 items-center'>
                            <IoMailSharp />
                            <p className='text-left max-sm:text-sm'>{HOTEL_MAIL1}</p>
                        </div>
                        <div className='flex flex-row gap-5 items-center'>
                            <FaPhoneAlt />
                            <p className='text-left max-sm:text-sm'>{HOTEL_PHONE1} | {HOTEL_PHONE2} | {HOTEL_PHONE3} | {HOTEL_PHONE4}</p>
                        </div>
                    </div>
                    <div className='flex flex-row gap-5 w-full'>
                        <form className='flex flex-col gap-2 w-full' onSubmit={(e) => {
                            handleSubmit(e)
                        }}>
                            <input
                                ref={nameRef}
                                type="text"
                                className='p-2 bg-[transparent] rounded-lg placeholder:text-gray-400 text-black  border border-black'
                                name="name"
                                placeholder="Your Name"
                                required
                            />
                            <input
                                ref={emailRef}
                                type="email"
                                className='p-2 bg-[transparent] rounded-lg placeholder:text-gray-400 text-black  border border-black'
                                name="email"
                                placeholder="Your Email"
                                required
                            />
                            <textarea
                                ref={messageRef}
                                name="message"
                                rows={6}
                                className='p-2 bg-[transparent] rounded-lg placeholder:text-gray-400 text-black  border border-black'
                                placeholder="Your Message"
                                required
                            ></textarea>
                            <button type="submit" className='border border-black bg-secondary hover:opacity-50 hover:delay-100 rounded-lg p-2 text-text_primary font-bold'>Send Message</button>
                        </form>
                    </div>
                </div>
                <img loading="lazy" alt="icon" src='/images/hotel-images/reception1.jpg' className='max-md:w-[80%] w-[40%] rounded-lg'></img>
            </div>
            <div className='w-[90%] h-[500px] max-sm:h-[300px] rounded-xl'>
                <iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Chaukori,%20Pithoragarh-262531,%20Uttrakhand+(Atithi%20Home%20Stay)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps systems</a></iframe></div>
        </div >
    );
};

export default ContactPage;
