import React from 'react'
import CustomButton from './CustomButton'

export default function LeftAndRightProductDescriptionSection({ type, children, heading, description, imageOnLeft }) {
    return (
        <div className='bg-tertiary h-[500px] max-md:h-fit flex flex-row gap-5 max-md:flex-col font-Lora items-center relative'>
            <div className={`w-[50%] h-full max-md:w-[100%] max-md:px-5 max-md:order-1 ${imageOnLeft ? 'order-1' : 'order-2'}`}>
                {children}
            </div>
            <div className={`w-[50%] h-full max-md:w-[100%] max-md:order-2 px-5 py-2 flex flex-col  gap-4 ${imageOnLeft ? "order-2" : "order-1"} items-center justify-center`}>
                <div className='w-[80%] flex flex-col gap-5'>
                    <p className='text-2xl line max-md:text-xl font-semibold'>{heading}</p>
                    <p className='line-clamp-3 max-md:text-sm max-h-[70%] text-ellipsis text-justify'>{description}</p>
                    <a href='/tarrif'>
                        <p className='max-md:text-sm text-justify text-xl'>Price starting at <span className='text-secondary font-bold'>₹ 2100</span></p>
                    </a>
                    <a href={"/rooms/" + type}>
                        <CustomButton text={"More Info"} />
                    </a>
                </div>
            </div>
        </div >
    )
}
