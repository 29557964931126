const NAVBARPAGES = [
    "", "Rooms", "About", "Gallery", "Contact", "Tarrif",
]
const HOTEL_NAME = "Atithi Home Stay, Chaukori Pithoragarh";
const HOTEL_ADDRESS = "Chaukori, Near Police Chowki, Pithoragarh-262531, Uttrakhand";
const HOTEL_MAIL1 = " atithihomestay@gmail.com";
const HOTEL_MAIL2 = "  yogeshvilla@gmail.com";
const HOTEL_PHONE1 = "+91 9999296503";
const HOTEL_PHONE2 = "+91 9315373630";
const HOTEL_PHONE3 = "+91 7248355619";
const HOTEL_PHONE4 = "+91 7500996464";


const ABOUT_US_DESCRIPTION = "Atithi Home stay is a beautiful home stay in Chaukori uttarakhand which is very close to the Himalayas, open from all sides, beautiful views and an environment full of beautiful greenery of tea gardens that provide peace to the mind. An excellent home stay for travelers who love solitude and peace, this haven is very close to Patal Bhuvaneshwar.\n \n I, Pitamber Lohani, have been involved in the hospitality and tourism business since 1998. During my tenure, I have worked considering hospitality as my passion and serving guests as my duty. My professional team associated with me for many years serves each and every guest visiting our premises with utmost honesty and dedication and is committed to fulfill their every small and big requirement with smiling faces. We treat our guests like God and serve them like God. We consider it our duty to keep the smile on the guests' faces till the time of departure and our reward is the praise given by them.\n \n View of snow-covered Himalayas, view of sunrise and sunset, near tea gardens, just 30 km away from the legendary cave of Patal Bhubaneswar, close to Kausani and a very beautiful hill town amidst many ancient temples."

const HOTEL_AMENITIES = ["View of snowcapped Himalayas", "Airy & spacious rooms", "Comfortable beds and mattresses", "Free WiFi", "Dining Table and Chairs", "Smart /LED TV with satellite tuner", "Cupboard", "Tea/Coffee Maker (Electric Kettle)", "Geysers (Hot and Cold Running Water)", "Attached private bathroom", "Premium Toiletries"]

const HOTEL_ROOM_TYPES = ["Standard Room", "Deluxe Twins Bed", "Super Deluxe Room"]

const HOTEL_TARRIFFS = {
    "Standard Room": "4500",
    "Deluxe Twins Bed": "5000",
    "Super Deluxe Room": "6000"
}

const ROOM_CATEGORIES = ["All Photos", "Rooms", "Out Door", "Reception"]

const TOTAL_ROOMS_BY_CATEGORY = {
    "all": 18,
    "room": 15,
    "outdoor": 1,
    "reception": 1,
}

const HOMEPAGE_CAROUSEL_IMAGES = [
    "outdoor1",
    "room3",
    "room5"
]


const HOTEL_ROOM_TYPES_AND_INFO = {
    "DX-Room": {
        longName: "Deluxe Room - Himalaya View",
        amenities: ["View of snowcapped Himalayas", "Airy & spacious rooms", "Comfortable beds and mattresses", "Free WiFi", "Dining Table and Chairs", "Smart /LED TV with satellite tuner", "Cupboard", "Tea/Coffee Maker (Electric Kettle)", "Geysers (Hot and Cold Running Water)", "Attached private bathroom", "Premium Toiletries"],
        description: "Welcome to our Deluxe Room with Himalayan View, a sanctuary of tranquility amidst the breathtaking beauty of the Himalayas. Step onto your private balcony or terrace and be greeted by the awe-inspiring sight of the snow-capped peaks, casting a spellbinding backdrop for your stay. Inside, you'll find a haven of comfort and luxury, with spacious interiors featuring plush beds and mattresses designed for restful nights and rejuvenating mornings. \n \n Indulge in modern amenities such as complimentary WiFi and a Smart/ LED TV with satellite tuner, allowing you to stay connected and entertained throughout your stay.For added convenience, each room is equipped with a tea/ coffee maker, ensuring you can savor a hot beverage while soaking in the panoramic views.\n \n Refresh in the attached private bathroom, complete with geysers for hot and cold running water and premium toiletries, promising a pampering experience.With thoughtful touches like a dining table, cupboard, and wake - up service, our Deluxe Room with Himalayan View offers everything you need for a memorable and comfortable stay amidst the natural splendor of the Himalayas.Experience true serenity and luxury in the heart of nature at our Deluxe Room – your gateway to an unforgettable Himalayan retreat.",
        photos: ["room4", "room1", "room2", "room3", "room5", "room6", "room7", "room8", "room10", "room11", "room13", "room14"],
        rates: {
            "Off Season": "2100",
            "Peak Season": "3900"
        }
    },
    "SuperDX-Room": {
        longName: "Super Deluxe Room with Kitchenette and Seating Area - Himalaya View",
        amenities: ["View of snowcapped Himalayas", "Airy & spacious rooms", "Comfortable beds and mattresses", "Free WiFi", "Dining Table and Chairs", "Smart /LED TV with satellite tuner", "Cupboard", "Tea/Coffee Maker (Electric Kettle)", "Geysers (Hot and Cold Running Water)", "Attached private bathroom", "Premium Toiletries", "Kitchenette/Separate seating area", "Microwave", "Gas Stove"],
        description: "Welcome to our Super Deluxe Room with Private Kitchen and Dining Area, the epitome of comfort and convenience in the heart of our home stay. Step into your spacious sanctuary, where modern amenities and thoughtful design come together to create an unforgettable retreat.\n \n Unwind in the cozy ambiance of your room, featuring comfortable beds and mattresses that promise a rejuvenating night's sleep. Indulge your culinary skills in the private kitchenette, equipped with all the essentials to whip up a delicious meal, which you can enjoy in the comfort of your dining area while taking in the serene surroundings. \n \n Refresh yourself in the attached private bathroom, complete with premium toiletries and geysers for hot and cold running water, ensuring a pampering experience. With complimentary WiFi access and a range of amenities at your disposal, including a Smart/LED TV with satellite tuner, our Super Deluxe Room with Kitchenette and Dining Area offers the perfect blend of luxury and convenience for an unforgettable stay. Welcome to your oasis of relaxation at our home stay.",
        photos: ["room15", "room7", "room12", "room13"],
        rates: {
            "Off Season": "3000",
            "Peak Season": "4900"
        }
    },

}


const PAGE_TITLES = {
    "home": "Atithi Home Stay | Welcome to Hospitality Excellence",
    "rooms": "Rooms | Atithi Home Stay",
    "about": "About | Atithi Home Stay",
    "gallery": "Gallery | Atithi Home Stay",
    "contact": "Contact | Atithi Home Stay",
    "tarrif": "Tarrif | Atithi Home Stay",
}

const META_CONTENT = {
    "home": {
        description: "Welcome to Atithi Home Stay located in Chaukori, Uttrakhand, your budget-friendly retreat with stunning Himalayan views. Experience comfort and serenity amidst nature. Book now!"
    },
    "rooms": {
        description: "Discover comfort and luxury at Atithi Home Stay. Our deluxe and super deluxe rooms offer breathtaking views of the snowcapped Himalayas, along with spacious interiors, modern amenities, and premium toiletries. Book your serene getaway now!"
    },
    "about": {
        description: "Atithi Home Stay, nestled in Chaukori, Uttarakhand, offers a serene escape near the majestic Himalayas and lush tea gardens. Our founder, Pitamber Lohani, brings decades of hospitality expertise, ensuring each guest receives unparalleled service and a memorable experience. Immerse yourself in tranquility, with breathtaking views, proximity to Patal Bhuvaneshwar, and a dedicated team committed to exceeding your expectations. Experience hospitality at its finest with us."
    },
    "gallery": {
        description: "Images showcasing the rooms,outdoor area and the view of the Atithi Home Stay"
    },
    "contact": {
        description: "Feel free to reach out to The Atithi Home Stay, Chaukori, Uttrakhand, for all your queries related to your stay and bookings with us. We are always happy to assist!"
    },
    "tarrif": {
        description: "Book best budget friendly rooms at cheaper rates with the Atithi Home Stay"
    },
}


const HOMEPAGE_DESCRIPTION = "Welcome to Atithi HomeStay, nestled in Chaukori, Uttrakhand, where the Himalayas embrace you with their serenity. With panoramic views of the majestic Himalayan range, our cozy abode offers a tranquil escape from city life. Feel at home amidst nature's splendor, waking up to snow-capped peaks and verdant valleys. \n \n At Atithi HomeStay, we aim to create a warm and welcoming atmosphere, ensuring your stay is unforgettable.Whether you seek adventure or a peaceful retreat, our home away from home promises comfort and relaxation.Come, unwind, and make Atithi HomeStay your second home in the Himalayas.amidst the Himalayas."

const HOMEPAGE_GALLERY_PREVIEW = [
    "outdoor1", "room1", "room15", "room4", "room7", "room11"
]

const HOMEPAGE_TITLE = "Atithi HomeStay - Your Second Home in the Himalayas"




export { NAVBARPAGES, HOTEL_ADDRESS, HOTEL_MAIL1, HOTEL_MAIL2, HOTEL_PHONE1, HOTEL_PHONE2, HOTEL_NAME, ABOUT_US_DESCRIPTION, HOTEL_AMENITIES, HOTEL_ROOM_TYPES, HOTEL_TARRIFFS, ROOM_CATEGORIES, TOTAL_ROOMS_BY_CATEGORY, HOTEL_ROOM_TYPES_AND_INFO, PAGE_TITLES, HOMEPAGE_CAROUSEL_IMAGES, HOMEPAGE_DESCRIPTION, HOMEPAGE_TITLE, HOMEPAGE_GALLERY_PREVIEW, HOTEL_PHONE3, HOTEL_PHONE4, META_CONTENT }