import React from 'react'
import { HOTEL_ROOM_TYPES, HOTEL_ROOM_TYPES_AND_INFO, HOTEL_TARRIFFS } from '../constants'

export default function TerrifTable() {
    return (
        <table class="table-auto w-full font-Lora border">
            <thead className=' border border-gray-400 w-full bg-secondary text-gray-200'>
                <tr>
                    <th className='py-2 text-xl max-sm:text-xs'>Room Category</th>
                    <th className='py-2 text-xl max-sm:text-xs'>Off Season</th>
                    <th className='py-2 text-xl max-sm:text-xs'>Peak Season</th>
                </tr>
            </thead>
            <tbody className=''>
                {
                    Object.keys(HOTEL_ROOM_TYPES_AND_INFO).map((type) => {
                        return (
                            <tr key={type} className='border border-gray-400'>
                                <td className='py-1 font-semibold border-r  border-gray-400 text-xl max-md:text-xs underline'><a href={`/rooms/${type}`}>{HOTEL_ROOM_TYPES_AND_INFO[type].longName}</a></td>
                                <td className='py-1 font-semibold border-r  border-gray-400 text-xl max-md:text-xs'>₹ {HOTEL_ROOM_TYPES_AND_INFO[type].rates["Off Season"]}</td>
                                <td className='py-1 font-semibold border-r  border-gray-400 text-xl max-md:text-xs'>₹ {HOTEL_ROOM_TYPES_AND_INFO[type].rates["Peak Season"]}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )
}
