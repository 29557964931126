import React from 'react'
import { ABOUT_US_DESCRIPTION, HOTEL_AMENITIES, HOTEL_NAME, META_CONTENT, PAGE_TITLES } from '../constants'
import HotelAmenities from '../components/HotelAmenities'
import CustomCarousel from '../components/Carousel';
import CustomButton from '../components/CustomButton';
import { IoMdContact } from 'react-icons/io';
import { getActiveImages } from '../helpers/galleryHelper';
import { Helmet } from 'react-helmet';


export default function AboutPage() {
    const onButtonClick = () => {
        window.open("/contact", "_self")
    }
    return (
        <div className='flex flex-col gap-10 justify-center items-center py-10'>
            <Helmet>
                <title>{PAGE_TITLES["about"]}</title>
                <meta name='description' content={META_CONTENT["about"].description} />
            </Helmet>
            <div className='w-[90%] flex flex-row max-md:flex-col justify-center items-center gap-10'>
                <div className='w-[50%] max-md:w-[90%] flex flex-col gap-14 items-start'>
                    <h1 className='font-Lora text-3xl max-md:text-xl text-secondary'>About {HOTEL_NAME}</h1>
                    <h2 className='whitespace-pre-line max-md:text-sm  text-gray-500 text-justify'>{ABOUT_US_DESCRIPTION}</h2>
                </div>
                <div className='w-[50%] max-md:w-full z-0'>
                    <CustomCarousel >
                        {
                            getActiveImages("Rooms").map((room) => {
                                return (
                                    <img key={room} alt={room} src={`images/hotel-images/${room}.jpg`} className='w-full h-[500px] max-md:h-[400px] object-cover rounded-xl ' />
                                )
                            })
                        }

                    </CustomCarousel>
                </div>
            </div>
            <div className=' flex flex-col gap-10 py-20 max-md:py-10 items-center w-full bg-gray-200'>
                <HotelAmenities amenities={HOTEL_AMENITIES} heading={"Amenities For You"} />
            </div>
            <div className='w-[90%]'>
                <CustomButton text={"Contact Us"} icon={<IoMdContact size={35} />} onClickHandler={onButtonClick} />
            </div>
        </div>
    )
}
