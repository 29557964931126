import React from 'react'

export default function RateCard({ heading, textColor, rate, bgColor }) {
    return (
        <div className={`${bgColor} ${textColor} w-full  py-5 flex flex-col gap-5 border border-tertiary shadow-xl font-Lora rounded-lg`} >
            <p className='text-lg max-sm:text-sm'>{heading}</p>
            <p className='text-2xl max-sm:text-lg font-bold'>₹ {rate}</p>
        </div >
    )
}
