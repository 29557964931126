// NotFoundPage.js
import React from 'react';

const NotFoundPage = () => {
    return (
        <div className="w-full h-full flex flex-col items-center justify-center">
            <h1 className="text-3xl font-bold text-gray-800 mb-4">Oops! Page Not Found</h1>
            <h1 className="text-8xl text-gray-600 mb-8">404</h1>
            <p className="text-lg text-gray-600 mb-8">Looks like you've stumbled upon something that doesn't exist.</p>
            <a href="/" aria-label='Go to homepage' className="text-blue-500 hover:underline">Go back to Home</a>
        </div>
    );
};

export default NotFoundPage;
